module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-45296436-2"},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
